// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

//@import "~bootstrap-icons/font/bootstrap-icons";

@import "node_modules/bootstrap-select/sass/bootstrap-select.scss";